
export default {
  components: {
    LoadingSpinner: () => import('@/components/loaders/LoadingSpinner.vue'),
    FilledButton: () => import('@/components/buttons/FilledButton.vue'),
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    supportedExtensions: {
      type: Array,
      default: () => ['mp3', 'm4a', 'mp4', 'flac', 'ogg', 'wav', 'aac', 'opus', 'oga', 'mogg', 'webm', 'flv', 'avi'],
    },
  },
  emits: {
    'media-selected': (file) => file, // File or array of files depending on the multiple prop
  },
  data: () => ({
    isDraggedFileIn: false,
  }),

  methods: {
    clearFileInput() {
      if (this.$refs.fileInput) this.$refs.fileInput.value = ''
    },
    onUploadButtonClick() {
      this.clearFileInput()
      this.$refs.fileInput.click()
    },
    emitMediaChosen(media) {
      this.$emit('media-selected', this.multiple ? media : media[0])
    },
    onChangeFile(media) {
      this.emitMediaChosen(media)
    },
    dragover(event) {
      event.preventDefault()
      this.isDraggedFileIn = true
    },
    dragleave(_) {
      this.isDraggedFileIn = false
    },
    drop(event) {
      event.preventDefault()
      this.isDraggedFileIn = false
      this.emitMediaChosen(event.dataTransfer.files)
    },
  },
}
