
import VueSlickCarousel from 'vue-slick-carousel'
import LazyHydrate from 'vue-lazy-hydration'
import { API_V1_PREFIX } from '@/constants/api'
import { getFileExtension } from '@/utils/file-utils'
import FilledButton from '@/components/buttons/FilledButton.vue'
import EmptyButton from '@/components/buttons/EmptyButton.vue'
import { formMetaFAQJson } from '@/utils/seo-meta'
import { concatUrls } from '@/utils/url-utils'
import NavLink from '@/components/links/NavLink.vue'
import PostAuthor from '@/components/blog/PostAuthor.vue'
import { getPostsList } from '@/api/blog/post/get-posts-list'
import PostCard from '@/components/blog/PostCard.vue'
import BaseButton from '@/components/buttons/BaseButton.vue'

export default {
  head() {
    return {
      __dangerouslyDisableSanitizersByTagID: {
        'faq-ldjson': ['innerHTML'], // Отключаем санитизацию для innerHTML faq-ldjson
        'how-to-transcribe-ldjson': ['innerHTML'], // Отключаем санитизацию для innerHTML how-to-transcribe-ldjson
      },
      title: this.meta_title,
      meta: [
        {
          name: 'description',
          content: this.meta_description,
        },
      ],
      script: [
        { hid: 'faq-ldjson', type: 'application/ld+json', innerHTML: formMetaFAQJson(this.questions) },
        {
          hid: 'how-to-transcribe-ldjson',
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'HowTo',
            name: 'How to convert audio or video to text in 3 steps',
            description: 'Follow these steps to transcribe your audio or video files into text.',
            estimatedCost: 'Free',
            supply: [
              {
                '@type': 'HowToSupply',
                name: 'Audio or Video File',
              },
            ],
            tool: [
              {
                '@type': 'HowToTool',
                name: 'Audio and Video Converter',
              },
              {
                '@type': 'HowToTool',
                name: 'Transcription Editor',
              },
            ],
            step: [
              {
                '@type': 'HowToStep',
                name: this.howToTranscribe.first_step.title,
                text: this.howToTranscribe.first_step.description,
                image: concatUrls(process.env.domain, this.howToTranscribe.first_step.image),
              },
              {
                '@type': 'HowToStep',
                name: this.howToTranscribe.second_step.title,
                text: this.howToTranscribe.second_step.description,
                image: concatUrls(process.env.domain, this.howToTranscribe.second_step.image),
              },
              {
                '@type': 'HowToStep',
                name: this.howToTranscribe.third_step.title,
                text: this.howToTranscribe.third_step.description,
                image: concatUrls(process.env.domain, this.howToTranscribe.third_step.image),
              },
            ],
          }),
        },
      ],
    }
  },
  name: 'home-page',
  layout: 'anonymous',
  components: {
    BaseButton,
    PostCard,
    PostAuthor,
    NavLink,
    EmptyButton,
    FilledButton,
    VueSlickCarousel,
    LazyHydrate,
    HomeNavbar: () => import('@/components/HomeNavbar'),
    Title: () => import('@/components/Home/Title'),
    Formats: () => import('@/components/Home/Formats'),
    Questions: () => import('@/components/Support'),
    MediaUploadBlock: () => import('@/components/MediaUploadBlock'),
    ChoseTranscriptionLanguageModal: () => import('@/components/modals/ChoseTranscriptionLanguageModal'),
  },

  data: () => ({
    meta_description: '',
    meta_title: '',
    settings: {
      centerMode: true,
      centerPadding: '20px',
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 5,
      speed: 500,
      responsive: [
        {
          breakpoint: 748,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    trialFileExtension: '',
    isChoseLanguageModalVisible: false,
    isProcessingFakeTranscription: false,
    isFakeTranscriptionSucceeded: false,
    countryFlags: [
      {
        imgSrc: require('@/assets/svg/country-flags/italy-flag.svg'),
        countryName: 'Italian',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/spain-flag.svg'),
        countryName: 'Spanish',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/germany-flag.svg'),
        countryName: 'Deutsch',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/denmark.svg'),
        countryName: 'Danish',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/france-flag.svg'),
        countryName: 'French',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/japan.svg'),
        countryName: 'Japanese',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/uk.svg'),
        countryName: 'English,British',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/australia.svg'),
        countryName: 'English,Australian',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/usa.svg'),
        countryName: 'English,US',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/india.svg'),
        countryName: 'Hindi,Indian',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/indonesia.svg'),
        countryName: 'Indonesian',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/malaysia.svg'),
        countryName: 'Malaysian',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/turkey.svg'),
        countryName: 'Turkish',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/thailand.svg'),
        countryName: 'Thai',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/china.svg'),
        countryName: 'Chinese',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/sk.svg'),
        countryName: 'Korean',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/israel.svg'),
        countryName: 'Hebrew',
      },
      {
        imgSrc: require('@/assets/svg/country-flags/netherlands.svg'),
        countryName: 'Dutch',
      },
    ],
    paginatedPosts: {
      results: [],
      params: {
        page_size: 3,
        page: 1,
        ordering: '-created',
      },
    },
  }),
  computed: {
    howToTranscribe() {
      return {
        first_step: {
          title: 'Upload your Audio Or Video',
          description:
            "Just click on the drop-down area or drag'n'drop your Audio or Video File and wait a few second for it to be uploaded.",
          image: require('@/assets/svg/business-presentation.svg'),
        },
        second_step: {
          title: 'Choose a language',
          description:
            'We offer over 140 languages for you to choose from. Select the language that corresponds to the language of your audio or video file. Wait for the file to be processed.',
          image: require('@/assets/svg/businessman_keynote.svg'),
        },
        third_step: {
          title: 'Edit & Download',
          description:
            'In less than 60 seconds you can start editing your text in our advanced editor. You can play the file, edit the timeline and speakers. Use shortcuts to perform editing actions faster. Share your file! Or simply download it to your device.',
          image: require('@/assets/svg/change-settings.svg'),
        },
      }
    },
    formats() {
      return this.$store.getters['displayedFormats']
    },
    posts() {
      return this.paginatedPosts.results.map((post) => ({
        ...post,
        created: new Date(post['created'] || 0),
      }))
    },
    reviews() {
      return this.$store.getters['reviews']
    },
    questions() {
      return this.$store.getters['questions']
    },
  },
  methods: {
    openDocumentation() {
      window.open(process.env.apiDocumentationUrl, '_blank')
    },

    mediaFileChosen(file) {
      this.showLanguageChooseModal(getFileExtension(file.name))
    },

    showLanguageChooseModal(fileExtension) {
      this.trialFileExtension = fileExtension
      this.isChoseLanguageModalVisible = true
    },
    closeLanguageChooseModal() {
      this.isChoseLanguageModalVisible = false
    },

    async languageChosen(_) {
      this.closeLanguageChooseModal()
      await this.processFakeTranscription(10)
    },

    async processFakeTranscription(seconds) {
      this.isFakeTranscriptionSucceeded = false
      this.isProcessingFakeTranscription = true
      await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
      this.isProcessingFakeTranscription = false
      this.isFakeTranscriptionSucceeded = true
    },

    prevReview() {
      const carousel = this.$refs.reviewsCarousel
      carousel.prev()
    },
    nextReview() {
      const carousel = this.$refs.reviewsCarousel
      carousel.next()
    },
    getImageUrl(server_url) {
      return `${process.env.browserRootURL}media/${server_url}`
    },
    afterPageChange(page) {
      const payload = this.reviews.map((item, i) =>
        page === i ? { ...item, isActive: true } : { ...item, isActive: false },
      )
      this.$store.commit('SET_REVIEWS', payload)
    },

    async sendFollowLink(query) {
      const data = {
        partner: query.partner,
        link_indefier: query.uuid,
        page: query.page,
      }
      await this.$axios.$post(`${API_V1_PREFIX}affiliate/invitation/follow_link/`, data).catch((error) => error)
    },
  },
  async mounted() {
    const localStorage = window.localStorage
    const query = this.$route.query
    const findKey = Object.keys(query).indexOf('partner') + 1
    if (!findKey) return
    await this.sendFollowLink(query)
    localStorage.setItem('ref.info', JSON.stringify(query))
  },

  async fetch() {
    // Last post and last posts list | Start
    await getPostsList
      .call(this, { ...this.paginatedPosts.params })
      .then((res) => {
        this.paginatedPosts = res || this.paginatedPosts
      })
      .catch((err) => {
        console.error(err)
      })
    // Last post and last posts list | End
  },

  async asyncData({ $axios, store }) {
    await store.dispatch('getFaq')
    await store.dispatch('getFormats')
    const { meta_title, meta_description } = await $axios.$get(`${API_V1_PREFIX}markup/home/`)
    return { meta_title, meta_description }
  },
}
