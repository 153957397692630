
export default {
  props: {
    width: {
      type: String,
      default: '155px'
    }
  }
}

