import { render, staticRenderFns } from "./EmptyButton.vue?vue&type=template&id=2f6a4320&scoped=true"
import script from "./EmptyButton.vue?vue&type=script&lang=js"
export * from "./EmptyButton.vue?vue&type=script&lang=js"
import style0 from "./EmptyButton.vue?vue&type=style&index=0&id=2f6a4320&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6a4320",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/code/components/Button/index.vue').default})
