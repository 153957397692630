import { render, staticRenderFns } from "./FilledButton.vue?vue&type=template&id=718be884&scoped=true"
import script from "./FilledButton.vue?vue&type=script&lang=js"
export * from "./FilledButton.vue?vue&type=script&lang=js"
import style0 from "./FilledButton.vue?vue&type=style&index=0&id=718be884&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718be884",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/code/components/Button/index.vue').default})
