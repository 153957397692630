
import PostAuthor from '@/components/blog/PostAuthor.vue'
import NavLink from '@/components/links/NavLink.vue'

export default {
  name: 'PostCard',
  components: { NavLink, PostAuthor },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getImageUrl(server_url) {
      return server_url ? `${process.env.browserRootURL}media/${server_url}` : ''
    },
  },
}
