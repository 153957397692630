
export default {
  name: 'PostMetaData',
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
}
