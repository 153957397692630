import { Duration } from '@/utils/date-time-utils'

export function getFileExtension(fileName) {
  const fileExt = fileName.split('.').pop()
  return fileExt.toLowerCase()
}

export class FileSize {
  constructor(size) {
    this.size = size
  }

  get bytes() {
    return this.size
  }

  get kilobytes() {
    return this.size / 1024
  }

  get megabytes() {
    return this.size / 1024 / 1024
  }

  get gigabytes() {
    return this.size / 1024 / 1024 / 1024
  }
}

export class SimpleFile {
  constructor(file) {
    this.file = file
  }

  get content() {
    return this.file
  }

  get name() {
    return this.file.name || ''
  }

  get size() {
    return new FileSize(this.file.size)
  }

  get extension() {
    return getFileExtension(this.name)
  }
}

export class AudioFile extends SimpleFile {
  constructor(file) {
    super(file)
    this.objectUrl = URL.createObjectURL(file)
    this.audio = new Audio(this.objectUrl)
    this.duration = new Duration(0)
  }

  async loadMetaData() {
    // Load audio metadata in sync manner.
    return new Promise((resolve, reject) => {
      this.audio.onloadedmetadata = () => {
        URL.revokeObjectURL(this.objectUrl)
        const durationMilliseconds = (this.audio.duration || 0) * 1000
        this.duration = new Duration(durationMilliseconds)
        resolve()
      }

      this.audio.onerror = () => {
        URL.revokeObjectURL(this.objectUrl)
        reject()
      }
    })
  }
}
