
export default {
  name: 'PostAuthor',
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getImageUrl(server_url) {
      return `${process.env.browserRootURL}media/${server_url}`
    },
  },
}
